import { amenities } from './amenities/amenities'
import { attendance } from './attendance/attendance'
import { blocks } from './blocks/blocks'
import { hostelers } from './hostelers/hostelers'
import { institutions } from './master/institution'
import { rooms } from './Rooms/rooms'

export const configurations = {
  blocks,
  rooms,
  amenities,
}

export const registrations = {
  hostelers,
  attendance,
}

export const master = {
  institutions,
}
