import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import {
  CreateUpdateBlock,
  GetBlocksParams,
  IBlocks,
} from 'pages/Configuration/Blocks/Blocks.interface'

export const blocks = {
  async fetchHostelBlocks(params?: GetBlocksParams): Promise<IBlocks[]> {
    const res = await axios.get('/square/hostel-blocks', {
      params: { ...params },
    })
    return res.data
  },

  async createOrUpdateBlock({ id, ...body }: CreateUpdateBlock) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id ? `/square/hostel-blocks/${id}` : '/square/hostel-blocks',
      data: body,
    }
    return axios(config).then((res) => res.data)
  },

  async deleteBlock(id: number) {
    const res = await axios.delete(`/square/hostel-blocks/${id}`)
    return res.data
  },
}
