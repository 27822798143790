import { ReactTable } from '@campxdev/shared'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { configurations } from 'services/api-services'
import * as XLSX from 'xlsx'

export const hostelCapacityReport = async () => {
  const floorReport = await configurations.rooms
    .fetchRoomsData()
    .then((res) => {
      const blocks = Object.entries(_.groupBy(res?.result, 'block.name'))?.map(
        (block) => {
          const blockDetails = Object.entries(
            _.groupBy(block[1], 'floor'),
          )?.map((item) => {
            const vacant = item[1]?.reduce(
              (prev, curr) => prev + (curr.capacity - curr.hostelersCount),
              0,
            )

            const occupied = item[1]?.reduce(
              (prevOccupied, currOccupied) =>
                prevOccupied + currOccupied.hostelersCount,
              0,
            )

            const capacity = item[1]?.reduce(
              (prevCapacity, currCapacity) =>
                prevCapacity + currCapacity.capacity,
              0,
            )

            return {
              block: block[0],
              vacant: vacant,
              floor: item[0],
              occupied: occupied,
              capacity: capacity,
            }
          })

          return blockDetails
        },
      )
      return blocks
    })

  return floorReport
}

export async function exportToExcel(data) {
  const excelData = data?.map((item, index) => ({
    Block_Name: item.block,
    Floor: item.floor,
    Capacity: item.capacity,
    Occupied: item.occupied,
    Vacant: item.vacant,
  }))

  const fileName = `Floor Wise Capacity report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export default function FloorWiseReport() {
  const [floorData, setFloorData] = useState(null)

  const { data, isLoading } = useQuery('hostelcapacityreport', () =>
    hostelCapacityReport(),
  )

  const columns = [
    {
      title: 'Block Name',
      dataIndex: 'block',
      key: 'block',
    },
    {
      title: 'Floor',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Total',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Occupied',
      dataIndex: 'occupied',
      key: 'occupied',
    },
    {
      title: 'Vacant',
      dataIndex: 'vacant',
      key: 'vacant',
    },
  ]

  useEffect(() => {
    var allFloorData = [].concat.apply([], data)
    setFloorData(allFloorData)
  }, data)

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => exportToExcel(floorData && floorData)}
        >
          Export
        </Button>
      </Box>

      <ReactTable
        key={'hostelcapacityreport'}
        dataSource={floorData ? floorData.map((item) => item) : []}
        rowKey={'id'}
        columns={columns}
        loading={isLoading}
        pagination={null}
      />
    </div>
  )
}
