import { lazy } from 'react'

const Dashboard = lazy(() => import('./Dashboard'))
const Attendance = lazy(() => import('./Attendance'))
const FeePayments = lazy(() => import('./FeePayments'))
const Hostelers = lazy(() => import('./Hostelers'))
const HostelersView = lazy(() => import('./Hostelers/HostelersView'))
const Reports = lazy(() => import('./Reports'))
const Amenities = lazy(() => import('./Configuration/Amenities'))
const Blocks = lazy(() => import('./Configuration/Blocks'))
const TicketingSystem = lazy(() => import('./Configuration/TicketingSystem'))
const Rooms = lazy(() => import('./Configuration/Rooms'))
const Tickets = lazy(() => import('./Tickets'))
const TicketsDetails = lazy(() => import('./Tickets/TicketDetails'))
const HostelBlockView = lazy(() => import('./Configuration/Blocks/BlockView'))
const People = lazy(() => import('./Configuration/People'))
export {
  Amenities,
  Attendance,
  Blocks,
  Dashboard,
  FeePayments,
  HostelBlockView,
  Hostelers,
  HostelersView,
  People,
  Reports,
  Rooms,
  TicketingSystem,
  Tickets,
  TicketsDetails,
}
