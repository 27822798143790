import {
  axios,
  Breadcrumbs,
  PageContent,
  PageHeader,
  TabsContainer,
} from '@campxdev/shared'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Services from './Services/Services'
import Users from './Users/Users'
const tabs = [
  {
    key: 'Services',
    label: 'Services',
    component: <Services />,
  },
  {
    key: 'Users',
    label: 'Users',
    component: <Users />,
  },
]

function TicketsSubpage() {
  const { id } = useParams()

  const { data: GetTicketData } = useQuery('TicketingSystemGroups', () =>
    axios.get(`/square/ticket-categories/${id}`).then((res) => res.data),
  )
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: 'Ticketing System Groups',
                to: '/configuration',
              },
              { name: `${GetTicketData?.name}`, to: null },
            ]}
          />
        }
      />
      <PageContent>
        <TabsContainer tabs={tabs} />
      </PageContent>
    </div>
  )
}

export default TicketsSubpage
