import {
  DrawerButton,
  FilterButton,
  SearchBar,
  SingleSelect,
  Table,
} from '@campxdev/shared'
import { Button } from '@mui/material'
import { batchOptions } from 'components/utils'
import moment from 'moment'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { reports } from 'services/api-services/reports-section/reports'
import * as XLSX from 'xlsx'
import { StyledBox, StyledBoxInner } from '../Styles'
import ReportFilters from './ReportFilters'

export async function exportToExcel(filters) {
  const data = await reports.fetchFeeDueData({
    ...filters,
    offset: undefined,
    limit: undefined,
  })
  if (!data) {
    alert('No Data Found')
    return
  }

  const excelData = data?.feeDues?.map((item, index) => ({
    Admission_Id: item.feeDue?.admissionId,
    Hosteler_Id: item.hostelerRoom?.hostelerId,
    Hosteler_Name: item.feeDue.admissionSubValue?.fullName,
    Room_Number: item.hostelerRoom?.room?.roomNumber,
    Total_Fee: item.feeDue?.payableAmount,
    Paid: item.feeDue?.debit,
    Due: item.feeDue?.totalDue,
    Year: item.hostelerRoom?.year,
    Batch: item.feeDue?.admissionSubValue.batch,
    Roll_No: item.feeDue?.admissionSubValue.rollNo,
  }))

  const fileName = `Fee Due Report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export const defaultObj = {
  filters: {
    hostelerBatch: batchOptions[0],
    limit: 10,
    offset: 0,
    blockId: [],
    year: '',
    search: '',
  },
  filtersApplied: false,
}

export default function FeeDueReport() {
  const queryClient = useQueryClient()
  const [state, setState] = useState(defaultObj)
  const { data, isLoading } = useQuery(
    [
      'feeDueReport',
      ...Object.keys(state.filters).map((key) => state.filters[key]),
    ],
    () => reports.fetchFeeDueData(state.filters),
  )

  const handlePagination = (value) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        offset: (value - 1) * prev.filters.limit,
      },
    }))
  }

  const handlePageLimit = (value: number) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        limit: value,
        offset: 0,
      },
    }))
  }

  const columns = [
    {
      title: 'Admission ID',
      dataIndex: 'feeDue.admissionId',
      key: 'feeDue.admissionId',
    },
    {
      title: 'Hosteler ID',
      dataIndex: 'hostelerRoom.hostelerId',
      key: 'hostelerRoom.hostelerId',
    },
    {
      title: 'Hosteler Name',
      dataIndex: 'feeDue.admissionSubValue.fullName',
      key: 'feeDue.admissionSubValue.fullName',
    },
    {
      title: 'Block',
      dataIndex: 'hostelerRoom.room.block.name',
      key: 'hostelerRoom.room.block.name',
    },
    {
      title: 'Room No.',
      dataIndex: 'hostelerRoom.room.roomNumber',
      key: 'hostelerRoom.room.roomNumber',
    },
    {
      title: 'Total Fee',
      dataIndex: 'feeDue.payableAmount',
      key: 'feeDue.payableAmount',
    },
    {
      title: 'Paid',
      dataIndex: 'feeDue.debit',
      key: 'feeDue.debit',
    },
    {
      title: 'Due',
      dataIndex: 'feeDue.totalDue',
      key: 'feeDue.totalDue',
    },
    {
      title: 'Year',
      dataIndex: 'hostelerRoom.year',
      key: 'hostelerRoom.year',
    },
    {
      title: 'Batch',
      dataIndex: 'feeDue.admissionSubValue.batch',
      key: 'feeDue.admissionSubValue.batch',
    },
    {
      title: 'Roll No.',
      dataIndex: 'feeDue.admissionSubValue.rollNo',
      key: 'feeDue.admissionSubValue.rollNo',
    },
  ]

  return (
    <>
      <StyledBox>
        <StyledBoxInner>
          <SingleSelect
            label={'Hosteler Batch'}
            name={'batch'}
            value={state.filters.hostelerBatch}
            options={batchOptions.map((item, index) => ({
              label: item,
              value: item,
            }))}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  hostelerBatch: e.target.value,
                },
              }))
            }}
          />
          <SearchBar
            onSearch={(value) =>
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  search: value,
                },
              }))
            }
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton
                onClick={open}
                filtersApplied={state.filtersApplied}
              />
            )}
            content={({ close }) => (
              <ReportFilters close={close} setState={setState} state={state} />
            )}
            title="Apply Filters"
          />
        </StyledBoxInner>

        <Button
          variant="outlined"
          onClick={() => exportToExcel(state?.filters)}
        >
          Export
        </Button>
      </StyledBox>

      <Table
        key={'feeDueReport'}
        rowKey={'id'}
        dataSource={data?.feeDues ? data.feeDues : []}
        columns={columns}
        loading={isLoading}
        pagination={{
          page: state.filters.offset / state.filters.limit,
          limit: state.filters.limit,
          onChange: handlePagination,
          onChangeLimit: handlePageLimit,
          totalCount: data?.count,
        }}
      />
    </>
  )
}
