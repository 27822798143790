import { Chips, SingleSelect, Spinner } from '@campxdev/shared'
import { Button, Stack, Typography } from '@mui/material'
import { useQuery, useQueryClient } from 'react-query'
import { configurations, master } from 'services/api-services'
import { useImmer } from 'use-immer'

const HostelersFilters = ({ close, setState }) => {
  const queryClient = useQueryClient()
  const [filters, setFilters] = useImmer({
    blockId: [],
    year: null,
    roomId: '',
    institutionId: '',
  })
  const { data: blocks, isLoading: loadingBlocks } = useQuery(
    'hostel-blocks',
    () => configurations.blocks.fetchHostelBlocks(),
    { enabled: !queryClient.getQueryData('hostel-blocks') },
  )
  const { data: rooms, isLoading: loadingRooms } = useQuery(
    ['hostel-rooms', filters.blockId],
    () => configurations.rooms.fetchRoomsData({ blockId: filters.blockId }),
    { enabled: !queryClient.getQueryData(['hostel-rooms', filters.blockId]) },
  )
  const { data: institutions } = useQuery(
    'institutions',
    () => master.institutions.fetchInstitutions(),
    { enabled: !queryClient.getQueryData('institutions') },
  )

  const handleApplyFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        blockId: filters.blockId,
        year: filters.year,
        roomId: filters.roomId,
        institutionId: filters.institutionId,
      },
      filtersApplied: true,
    }))
    close()
  }

  const clearFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        blockId: [],
        amenities: '',
        year: null,
        roomId: '',
        institutionId: '',
      },
      filtersApplied: false,
    }))
    close()
  }

  if (loadingBlocks || loadingRooms) return <Spinner />

  return (
    <>
      <Stack gap={1}>
        <Typography variant="subtitle2">Select Block</Typography>
        <Chips
          singleSelection={false}
          list={blocks?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))}
          selected={filters?.blockId}
          onSelected={(newState) => {
            setFilters((s) => {
              s.blockId = newState
            })
          }}
        />
        <SingleSelect
          label="Select Room"
          options={
            rooms?.result?.map((item) => ({
              label: item?.roomNumber,
              value: item?.id,
            })) || []
          }
          value={filters.roomId}
          onChange={(e) => {
            setFilters((s) => {
              s.roomId = e.target.value
            })
          }}
        />
        <SingleSelect
          label="Select Institution"
          options={
            institutions?.map((item) => ({
              label: item?.name,
              value: item?.id,
            })) || []
          }
          value={filters.institutionId}
          onChange={(e) => {
            setFilters((s) => ({ ...s, institutionId: e.target.value }))
          }}
        />

        <SingleSelect
          value={filters.year}
          label={'Year'}
          name={'year'}
          options={['1', '2', '3', '4', '5', '6'].map((item) => {
            return { label: item, value: item }
          })}
          onChange={(e) => {
            setFilters((s) => {
              s.year = +e.target.value
            })
          }}
        />
      </Stack>
      <Stack direction="row" gap={'20px'} marginTop={'20px'}>
        <Button fullWidth size="large" onClick={handleApplyFilters}>
          Apply Filters
        </Button>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => clearFilters()}
        >
          Clear Filters
        </Button>
      </Stack>
    </>
  )
}

export default HostelersFilters
