import FeeDueReport from './ReportPages/FeeDueReport/FeeDueReport'
import FeePaymentsReport from './ReportPages/FeePaymentsReport'
import FloorWiseReport from './ReportPages/FloorWiseReport'
import HostelCapacityReport from './ReportPages/HostelCapacityReport'
import HostelersList from './ReportPages/HostelersList/HostelersList'
const basePath = '/hostels/reports'

export const links = [
  // {
  //   title: 'Attendance',
  //   items: [
  //     {
  //       label: 'Attendance Report',
  //       to: `${basePath}/attendanace-report`,
  //       component: AttendanceReport,
  //     },
  //     {
  //       label: 'Absentees Report',
  //       to: `${basePath}/absentees-report`,
  //     },
  //     {
  //       label: 'Checked out Students',
  //       to: `${basePath}/checked-out-students`,
  //     },
  //     {
  //       label: 'Checked in Students',
  //       to: `${basePath}/checked-in-students`,
  //     },
  //     {
  //       label: 'Monthly Attendance',
  //       to: `${basePath}/monthly-attendance`,
  //     },
  //   ],
  // },
  {
    title: 'General',
    items: [
      {
        label: 'Hostelers List',
        to: `hostelers-list`,
        component: HostelersList,
      },
      // {
      //   label: 'Hostelers Room wise report',
      //   to: `${basePath}/hostelers-room-wise-report`,
      // },
      {
        label: 'Hostel Capacity report',
        to: `hostel-capacity-report`,
        component: HostelCapacityReport,
      },
      {
        label: 'Floor Wise Report',
        to: `floor-wise-report`,
        component: FloorWiseReport,
      },
      // {
      //   label: 'Hostel Admins',
      //   to: `${basePath}/hostel-admins`,
      // },
    ],
  },
  {
    title: 'Payments',
    items: [
      {
        label: 'Fee Dues Report',
        to: `fee-dues-report`,
        component: FeeDueReport,
      },
      {
        label: 'Fee Payments Report',
        to: `fee-payments-report`,
        component: FeePaymentsReport,
      },
    ],
  },
]
