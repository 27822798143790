import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import {
  CreateFaceData,
  CreateUpdateHosteler,
  GetHostelerParams,
  GetHostelersParams,
  IHosteler,
} from 'pages/Hostelers/Hostelers.interface'

export const hostelers = {
  async searchStudents(params: any) {
    const res = await axios.get(`/paymentx/admissions/all-admissions`, {
      params,
    })
    return res.data.admissions
  },

  async fetchHostelers(params: GetHostelersParams): Promise<IHosteler> {
    const res = await axios.get(`/square/hostelers`, {
      params: {
        ...params,
      },
    })
    return res.data
  },

  async fetchHosteler({ id, hostelerBatch }: GetHostelerParams) {
    return await axios
      .get(`/square/hostelers/${id}`, {
        params: {
          hostelerBatch,
        },
      })
      .then((res) => res.data)
  },

  async validateHosteler({ id }: { id: number }) {
    return axios.get(`/paymentx/admissions/${id}`).then((res) => res.data)
  },

  async fetchVacancies(params: { blockId?: number; hostelerBatch: string }) {
    const res = await axios.get(`/square/hostelers/vacancies`, {
      params,
    })
    return res.data
  },

  async createUpdateHosteler(body: CreateUpdateHosteler) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: body,
      url: `/square/hostelers`,
    }
    return axios(config).then((res) => res.data)
  },

  async fetchHostelFeeTypes() {
    const res = await axios.get(`/paymentx/fee-types`)
    return res.data
  },

  async deleteHosteler(id: number) {
    const res = await axios.delete(`/square/hostelers/${id}`)
    return res.data
  },

  async createFaceData({ id, ...postBody }: CreateFaceData) {
    return await axios
      .post(`/square/hostelers/${id}/reset-face-data`, {
        ...postBody,
      })
      .then((res) => res.data)
  },
}
