import {
  Drawer,
  DrawerProps,
  ListItemButton,
  ListSubheader,
  styled,
} from '@mui/material'
const headerHeight = '64px'
const sideNavWidth = '220px'

const drawerWidth = 240

export const StyledPageContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingLeft: '16px',
  paddingRight: '16px',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export const StyledDrawer = styled((props: DrawerProps) => (
  <Drawer
    {...props}
    variant="persistent"
    anchor="left"
    PaperProps={{
      sx: {
        left: sideNavWidth,
        top: headerHeight,
      },
    }}
  />
))(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: theme.palette.secondary.light,
  },
}))

export const StyledListItemButton = styled(ListItemButton)<{
  isActive: boolean
}>(({ theme, isActive }) => ({
  ...(isActive && {
    background: theme.palette.secondary.lighter,
    ':hover': {
      background: theme.palette.secondary.lighter,
    },
  }),
}))

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.yellow,
  background: 'transparent',
}))
