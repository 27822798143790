import { Close, Menu } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs,
  IconButton,
  List,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { lazy, useState } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { links } from '../links'
import {
  StyledDrawer,
  StyledListItemButton,
  StyledListSubheader,
  StyledPageContent,
} from './styles'
import { ErrorBoundary, PageHeader, useRouteMatch } from '@campxdev/shared'

const HostelReports = lazy(() => import('../../Reports'))

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '28px',
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
    fontWeight: 600,
  },
}))
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  transition: 'color 0.2s ease-in-out',
  fontFamily: 'Poppins',
  '&:hover': {
    color: theme.palette.common.yellow,
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  marginRight: '5px',
}))

export default function ReportsViewLayout() {
  const match: any = useRouteMatch('/hostels/reports/:reportName')

  const pageTitle = match.url
    .split('/')
    [match.url.split('/').length - 1].toUpperCase()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const getIsActiveLink = (link: string) => {
    return (
      link.split('/').slice(-1)[0] ===
      match.url.split('/')[match.url.split('/').length - 1]
    )
  }

  return (
    <Box display={'flex'}>
      <StyledDrawer open={open}>
        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 10px',
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </Box>
        <List>
          {links.map((item, parentIndex) => (
            <>
              <StyledListSubheader>{item.title}</StyledListSubheader>
              <>
                {item.items.map((link, index) => (
                  <Link to={link.to} key={index}>
                    <StyledListItemButton isActive={getIsActiveLink(link.to)}>
                      <ListItemText primary={link.label} />
                    </StyledListItemButton>
                  </Link>
                ))}
              </>
            </>
          ))}
        </List>
      </StyledDrawer>

      <StyledPageContent open={open}>
        <PageHeader
          noPadding
          title={
            <StyledStack direction={'row'} alignItems="center">
              {open ? (
                <Box className="empty"></Box>
              ) : (
                <StyledIconButton onClick={handleDrawerOpen}>
                  <Menu />
                </StyledIconButton>
              )}

              <Breadcrumbs separator={<Typography variant="h3">/</Typography>}>
                <StyledLink to={'/reports'}>Reports</StyledLink>
                <Typography variant="h3">{pageTitle}</Typography>
              </Breadcrumbs>
            </StyledStack>
          }
        />

        <ErrorBoundary>
          <Routes>
            <Route index={true} element={<HostelReports />} />
            {links
              .map((item) => item.items)
              .flat()
              .map((route, index) => (
                <Route
                  path={route.to}
                  element={<route.component />}
                  key={index}
                />
              ))}
          </Routes>
        </ErrorBoundary>
      </StyledPageContent>
    </Box>
  )
}
