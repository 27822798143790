import {
  AppHeader,
  ErrorBoundary,
  Helmet,
  Spinner,
  useAuth,
} from '@campxdev/shared'
import { StyledHeaderContainer } from '@campxdev/shared/styled-components'
import { Outlet } from 'react-router-dom'

export default function AppLayout() {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) return <Spinner />

  return (
    <ErrorBoundary>
      <Helmet
        user={data?.user}
        appTitle="Hostels | Campx"
        favicon={data?.assets?.logo_square}
      />
      <ErrorBoundary>
        <StyledHeaderContainer>
          <AppHeader
            userBoxActions={[]}
            clientLogo={data?.assets?.logo}
            fullName={data?.user?.fullName}
          />
        </StyledHeaderContainer>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </ErrorBoundary>
    </ErrorBoundary>
  )
}
