import {
  ActionButton,
  axios,
  axiosErrorToast,
  DeleteButton,
  DrawerButton,
  PageContent,
  PageHeader,
  Table,
} from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import UserForm from './UsersForm'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'

function Users() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { data, isLoading, error } = useQuery('TicketDetails', () =>
    axios.get(`/square/ticket-categories/${id}`).then((res) => res.data),
  )
  const onDelete = async (Data) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/square/ticket-categories/users/remove',
      data: {
        userId: Data.id,
        categoryId: id,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('TicketDetails')

      toast.error('Deleted Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  const colums = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',

      key: 'mobile',
      render: (_, row) => {
        return row.mobile ? row.mobile : <span>---</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return <DeleteButton onClick={() => onDelete(row)} />
      },
    },
  ]
  return (
    <Box sx={{ marginLeft: '-20px' }}>
      <PageHeader
        title={'Users'}
        actions={[
          <DrawerButton
            key={'1'}
            title="Add Users"
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add New</ActionButton>
            )}
            content={({ close }) => <UserForm data={null} hideDialog={close} />}
          />,
        ]}
      />
      <PageContent>
        <Table
          key={'tickets'}
          dataSource={data?.users}
          rowKey={'id'}
          columns={colums}
          loading={isLoading}
        />
      </PageContent>
    </Box>
  )
}

export default Users
