import { Box, InputBase, styled, Typography } from '@mui/material'

export const StyledRemarksContainer = styled(Box)({
  height: '350px',
  borderRadius: '10px',
  border: '1px solid #1212121A',
  marginBottom: '20px ',
})
export const StyledLetterBackGround = styled(Box)({
  width: '60px',
  height: '60px',
  display: 'flex',
  backgroundColor: 'black',
  justifyContent: 'center',
  alignItems: 'center',
})
export const StyledNamecontainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  margin: '25px',
})

export const StyledLetter = ({ letter }) => {
  //letter?.split(' ')[0][0]}${letter?.split(' ')[1][0]
  const value = `${letter?.split(' ')[0][0]}`

  return (
    <StyledLetterBackGround>
      <Typography variant="h1" sx={{ color: 'white' }}>
        {value.toLocaleUpperCase()}
      </Typography>
    </StyledLetterBackGround>
  )
}
export const StyledBox = styled(Box)({
  width: '900px',
  height: 'calc(100vh - 100px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },
})
