import { ReactTable } from '@campxdev/shared'
import { Box, Button } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { configurations } from 'services/api-services'
import * as XLSX from 'xlsx'

export async function exportToExcel(data) {
  const res = await configurations.rooms.fetchRoomsData()
  if (!data) {
    alert('Data not Available')
    return
  }

  const excelData = res?.result?.map((item, index) => ({
    SNo: item.id,
    Block_Name: item.block.name,
    Room_Number: item.roomNumber,
    total: item.capacity,
    Occupied: item.hostelersCount,
    Vacant: item.capacity - item.hostelersCount,
  }))

  const fileName = `Hostel Capacity report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export default function HostelCapacityReport() {
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  })

  const { data, isLoading } = useQuery(
    ['hostelcapacityreport', ...Object.values(filters)],
    () => configurations.rooms.fetchRoomsData(filters),
  )

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Block',
      dataIndex: 'block.name',
      key: 'block',
    },
    {
      title: 'Room No.',
      dataIndex: 'roomNumber',
      key: 'roomNumber',
    },
    {
      title: 'Total',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Occupied',
      dataIndex: 'hostelersCount',
      key: 'hostelersCount',
    },
    {
      title: 'Vacant',
      dataIndex: 'vacant',
      key: 'vacant',
      render: (_, row) => row.capacity - row.hostelersCount,
    },
  ]

  const handlePagination = (newOffset) => {
    setFilters((prev) => ({
      ...prev,
      offset: newOffset,
    }))
  }

  const handlePageLimit = (value: number) => {
    setFilters((prev) => ({
      ...prev,
      limit: value,
      offset: 0,
    }))
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <Button variant="outlined" onClick={() => exportToExcel(data?.result)}>
          Export
        </Button>
      </Box>

      <ReactTable
        key={'hostelcapacityreport'}
        dataSource={data?.result}
        rowKey={'id'}
        columns={columns}
        loading={isLoading}
        pagination={{
          limit: filters.limit,
          onChange: handlePagination,
          totalCount: data?.count,
          onChangeLimit: handlePageLimit,
        }}
      />
    </div>
  )
}
