import {
  ActionButton,
  axios,
  DrawerButton,
  DropDownButton,
  PageContent,
  PageHeader,
  Table,
  useHistory,
} from '@campxdev/shared'
import { useQuery } from 'react-query'
import ServicesForm from './ServicesForm'
import { useParams } from 'react-router-dom'
import { MoreVert } from '@mui/icons-material'
import { Box } from '@mui/material'

function Services() {
  const { id } = useParams()
  const {
    data: GetTicketData,
    isLoading,
    error,
  } = useQuery('TicketingSystemGroups', () =>
    axios.get(`/square/ticket-categories/${id}`).then((res) => res.data),
  )

  const colums = [
    {
      title: 'Service name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'user.fullName',
      key: 'user.fullName',
      render: (_, row) => {
        return (
          <>
            {/* <DropDownButton
              icon={{
                icon: <MoreVert />,
                outlined: false,
              }}
              menu={[
                {
                  label: 'Mark as Not Submitted',
                  onClick: () => {},
                },
                {
                  label: 'Mark as Absent',
                  onClick: () => {},
                },
              ]}
            /> */}
          </>
        )
      },
    },
  ]
  return (
    <Box sx={{ marginLeft: '-20px' }}>
      <PageHeader
        title={'Services'}
        actions={[
          <DrawerButton
            title="title"
            key={1}
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add New </ActionButton>
            )}
            content={({ close }) => (
              <ServicesForm data={null} hideDialog={close} />
            )}
          />,
        ]}
      />
      <PageContent>
        <Table
          key={'tickets'}
          dataSource={GetTicketData?.services ?? []}
          rowKey={'id'}
          columns={colums}
          loading={isLoading}
        />
      </PageContent>
    </Box>
  )
}

export default Services
