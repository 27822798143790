import { axios } from '@campxdev/shared'
export const getTicketDetails = async (id: string) => {
  let subjectData = await axios.get(`/square/tickets/${id}`)
  return subjectData?.data
}
export const getTicketCategoryDetails = async (id: string) => {
  let subjectData = await axios.get(`/square/ticket-categories/${id}`)
  return subjectData?.data
}
export const ticketStatusOptions = [
  {
    label: 'OPEN',
    value: 'OPEN',
  },
  {
    label: 'IN_PROGRESS',
    value: 'IN_PROGRESS',
  },
  {
    label: 'RESOLVED',
    value: 'RESOLVED',
  },
  {
    label: 'CLOSED',
    value: 'CLOSED',
  },
]
export const batchOptions = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
]
