import { Chips, SingleSelect, Spinner } from '@campxdev/shared'
import { Button, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { configurations } from 'services/api-services'
import { useImmer } from 'use-immer'

const defaultValues = {
  year: null,
  blockId: [],
}

function ReportFilters({ close, state, setState }) {
  const queryClient = useQueryClient()
  const [filters, setFilters] = useImmer(defaultValues)
  const { data: blocks, isLoading: loadingBlocks } = useQuery(
    'hostel-blocks',
    () => configurations.blocks.fetchHostelBlocks(),
    { enabled: !queryClient.getQueryData('hostel-blocks') },
  )

  const handleApplyFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        year: filters.year,
        blockId: filters.blockId,
      },
      filtersApplied: true,
    }))
    close()
  }

  const clearFilters = () => {
    setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        year: null,
        blockId: [],
      },
      filtersApplied: false,
    }))
    close()
  }

  useEffect(() => {
    setFilters(state.filters)
  }, [])

  if (loadingBlocks) return <Spinner />

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="subtitle2">Select Block</Typography>
          <Chips
            singleSelection={false}
            list={blocks?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            selected={filters.blockId}
            onSelected={(value) => {
              setFilters((s) => {
                s.blockId = value
              })
            }}
          />
          <SingleSelect
            label={'Year'}
            name={'year'}
            value={filters.year}
            options={[1, 2, 3, 4, 5, 6]?.map((item) => {
              return { label: item.toString(), value: item.toString() }
            })}
            onChange={(e) => {
              setFilters((s) => {
                s.year = e.target.value
              })
            }}
          />
        </Stack>
        <Stack direction="row" gap={'20px'} marginTop={'20px'}>
          <Button fullWidth size="large" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default ReportFilters
