import { axios } from '@campxdev/shared'
import {
  CreateUpdateAmenity,
  GetAmenitiesParams,
  IAmenities,
} from 'pages/Configuration/Amenities/Amenitites.interface'

export const amenities = {
  async fetchRoomAmenities(params?: GetAmenitiesParams): Promise<IAmenities[]> {
    const res = await axios.get('/square/hostel-room-amenities', {
      params: { ...params },
    })
    return res.data
  },

  async createUpdateAmenity({ id, ...body }: CreateUpdateAmenity) {
    const config = {
      method: id ? 'PUT' : 'POST',
      url: id
        ? `/square/hostel-room-amenities/${id}`
        : '/square/hostel-room-amenities',
      data: {
        ...body,
      },
    }
    return axios(config).then((res) => res.data)
  },

  async deleteAmenity(id: number) {
    const res = await axios.delete(`/square/hostel-room-amenities/${id}`)
    return res.data
  },
}
