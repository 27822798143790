import { FormSingleSelect, Spinner } from '@campxdev/shared'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { ticketStore } from './Tickets'
import { useForm } from 'react-hook-form'
import { getCategories, priorityOptions, ticketStatusOptions } from './Services'
import { useQuery } from 'react-query'

function TicketsFilter() {
  const { data: ticketsData, isLoading: ticketLoading } = useQuery(
    'TicketCategories',
    () => getCategories(),
  )

  const onSubmit = (formData) => {
    ticketStore.update((s) => {
      s.priority = formData.priority
      s.statuses = formData.status
      s.categoryId = formData.categoryId
      s.assignedTo = formData.assignedTo
    })
  }
  const { control, handleSubmit, watch } = useForm({})

  if (ticketLoading) return <Spinner />
  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '350px' }}>
          <StyledFiltersContainer>
            <StyledFiltersHeader>
              <Typography variant="h6">Apply Filters</Typography>
            </StyledFiltersHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2.5} sx={{ padding: '20px' }}>
                <FormSingleSelect
                  name="status"
                  label={'Select Status'}
                  control={control}
                  firstItemEmpty
                  required
                  options={ticketStatusOptions?.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
                <FormSingleSelect
                  name="priority"
                  label={'Select Priority'}
                  control={control}
                  firstItemEmpty
                  required
                  options={priorityOptions?.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
                <FormSingleSelect
                  name="categoryId"
                  label={'Select Group'}
                  control={control}
                  firstItemEmpty
                  required
                  options={ticketsData?.result?.map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                />
                <FormSingleSelect
                  name="assignedTo"
                  label={'Select Assignee'}
                  control={control}
                  firstItemEmpty
                  required
                  options={
                    ticketsData?.result
                      .find((item) => item?._id === watch('categoryId'))
                      ?.users?.map((item) => ({
                        label: item.fullName,
                        value: item.userId,
                      })) ?? []
                  }
                />

                <Button type="submit" fullWidth>
                  Get Students
                </Button>
              </Stack>
            </form>
          </StyledFiltersContainer>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketsFilter

export const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  border: theme?.borders?.grayLight,
  borderRadius: '10px',
  maxWidth: '350px',
  flexShrink: 0,
}))
export const StyledFiltersHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.light,
  padding: '20px',
  borderRadius: '10px 10px 0 0',
}))
