import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import {
  CreateUpdateRoom,
  GetRoomsParams,
  IRooms,
} from 'pages/Configuration/Rooms/Rooms.interface'

export const rooms = {
  async fetchRoomsData(params?: GetRoomsParams): Promise<IRooms> {
    const res = await axios.get('/square/hostel-rooms', {
      params: { ...params },
    })
    return res.data
  },

  async createUpdateRoom({ id, ...body }: CreateUpdateRoom) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id ? `/square/hostel-rooms/${id}` : '/square/hostel-rooms',
      data: body,
    }
    return axios(config).then((res) => res.data)
  },

  async deleteRoom(id: number) {
    const res = await axios.delete(`/square/hostel-rooms/${id}`)
    return res.data
  },
}
