import {
  FormTextField,
  FormSingleSelect,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { Button, Stack } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useForm } from 'react-hook-form'
import { useQueryClient, useQuery } from 'react-query'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  name: yup.string().required('Service Name is required'),
})

function ServicesForm({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
  userdata?: any
}) {
  const queryClient = useQueryClient()
  const { id } = useParams()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
    },
  })

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/square/ticket-categories/services/add',
      data: {
        categoryId: id,
        ...formData,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('TicketingSystemGroups')
      hideDialog()
      toast.success('Created Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          label={'Service name'}
          name={'name'}
          control={control}
          required
        />

        <Stack direction="row" gap={2} mt={2}>
          <Button type="submit" fullWidth>
            {data ? 'Update Group ' : 'Create Group '}
          </Button>
          <Button variant="outlined" fullWidth onClick={() => hideDialog()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
export default ServicesForm
