import {
  DrawerButton,
  FilterButton,
  ReactTable,
  SearchBar,
  SingleSelect,
} from '@campxdev/shared'
import { Button } from '@mui/material'
import { batchOptions } from 'components/utils'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { registrations } from 'services/api-services'
import { StyledBox, StyledBoxInner } from '../Styles'
import { exportToExcel } from '../service'
import HostelersFilters from './HostelersFilters'

export const defaultObj = {
  filters: {
    hostelerBatch: batchOptions[0],
    limit: 10,
    offset: 0,
    blockId: [],
    search: '',
  },
  filtersApplied: false,
}

export default function HostelersList() {
  const [state, setState] = useState(defaultObj)
  const { data, isLoading } = useQuery(['hostelersList', state.filters], () =>
    registrations.hostelers.fetchHostelers({ ...state.filters }),
  )

  const columns = [
    {
      title: 'Hosteler ID',
      dataIndex: 'hostelerDetails.hostelerId',
      key: 'hostelerDetails.hostelerId',
    },
    {
      title: 'Name',
      dataIndex: 'admission.fullName',
      key: 'admission.fullName',
    },
    { title: 'Batch', dataIndex: 'admission.batch', key: 'admission.batch' },
    {
      title: 'Admission ID',
      dataIndex: 'admission.uniqueId',
      key: 'admission.uniqueId',
    },
    {
      title: 'Roll No.',
      dataIndex: 'admission.rollNo',
      key: 'admission.rollNo',
    },
    {
      title: 'Father Name',
      dataIndex: 'admission.guardianDetails.fatherName',
      key: 'admission.guardianDetails.fatherName',
    },
    {
      title: 'Block',
      dataIndex: 'hostelerDetails.room.block.name',
      key: 'hostelerDetails.room.block.name',
    },
    {
      title: 'Year',
      dataIndex: 'hostelerDetails.year',
      key: 'hostelerDetails.year',
    },
    {
      title: 'Room No.',
      dataIndex: 'hostelerDetails.room.roomNumber',
      key: 'hostelerDetails.room.roomNumber',
    },
    {
      title: 'Amenities',
      dataIndex: 'hostelerDetails.room.amenities',
      key: 'hostelerDetails.room.amenities',
    },
    {
      title: 'Mobile',
      dataIndex: 'admission.mobile',
      key: 'admission.mobile',
    },
    {
      title: 'Joined Date',
      dataIndex: 'hostelerDetails.createdAt',
      key: 'hostelerDetails.createdAt',
      render: (_, row) =>
        moment(row?.hostelerDetails?.createdAt).format('DD/MM/YYYY'),
    },
    {
      title: 'Total Fee',
      dataIndex: 'fees.totalFee',
      key: 'totalFee',
    },
    {
      title: 'Total Paid',
      dataIndex: 'fees.totalPaid',
      key: 'totalPaid',
    },
    {
      title: 'Total Due',
      dataIndex: 'fees.totalDue',
      key: 'totalDue',
    },
  ]

  const handlePagination = (newOffset: number) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        offset: newOffset,
      },
    }))
  }

  const handlePageLimit = (value: number) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        limit: value,
      },
    }))
  }

  return (
    <>
      <StyledBox>
        <StyledBoxInner>
          <SingleSelect
            label={'Hosteler Batch'}
            name={'batch'}
            value={state.filters.hostelerBatch}
            options={batchOptions.map((item, index) => ({
              label: item,
              value: item,
            }))}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  hostelerBatch: e.target.value,
                },
              }))
            }}
          />
          <SearchBar
            onSearch={(value) => {
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  search: value,
                },
              }))
            }}
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton
                onClick={open}
                filtersApplied={state.filtersApplied}
              />
            )}
            content={({ close }) => (
              <HostelersFilters close={close} setState={setState} />
            )}
            title="Apply Filters"
          />
        </StyledBoxInner>
        <Button variant="outlined" onClick={() => exportToExcel(state.filters)}>
          Export
        </Button>
      </StyledBox>

      <ReactTable
        key={'hostelersList'}
        dataSource={data?.result ?? []}
        rowKey={'id'}
        columns={columns}
        loading={isLoading}
        pagination={{
          page: state.filters.offset / state.filters.limit,
          limit: state.filters.limit,
          onChange: handlePagination,
          totalCount: data?.count,
          onChangeLimit: handlePageLimit,
        }}
      />
    </>
  )
}
