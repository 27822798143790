import { Permission } from '@campxdev/shared'
import {
  AdmissionsIcon,
  HosteIcon,
  PaymentsIcon,
  PeopleIcon,
  ReportIcon,
  RoomsIcon,
  TicketsIcon,
} from './icons'

export interface IMenuItem {
  title: string
  path: string
  icon?: any
  children?: Array<IMenuItem>
  permissionKey?: string
}

export const appMenu: Array<IMenuItem> = [
  {
    title: 'Hostelers',
    path: '/hostelers',
    icon: AdmissionsIcon,
    permissionKey: Permission.CAN_HOSTELER_VIEW,
  },
  {
    title: 'Ticketing System',
    path: '/',
    icon: TicketsIcon,
    permissionKey: Permission.CAN_HOSTEL_TICKETS_VIEW,
    children: [
      {
        title: 'Tickets',
        path: 'tickets',
        permissionKey: Permission.CAN_HOSTEL_TICKETS_VIEW,
      },
      {
        title: 'Configuration',
        path: 'configuration',
        permissionKey: Permission.CAN_HOSTEL_TICKET_GROUP_VIEW,
      },
    ],
  },
  {
    title: 'Attendance',
    path: '/attendance-marking',
    icon: PaymentsIcon,
    permissionKey: Permission.CAN_HOSTEL_FEE_PAYMENTS_VIEW,
  },
  {
    title: 'Fee Payments',
    path: '/fee-payments',
    icon: PaymentsIcon,
    permissionKey: Permission.CAN_HOSTEL_FEE_PAYMENTS_VIEW,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: ReportIcon,
    permissionKey: Permission.CAN_HOSTEL_REPORTS_VIEW,
  },
  {
    title: 'Blocks',
    path: 'blocks',
    icon: HosteIcon,
    permissionKey: Permission.CAN_HOSTEL_BLOCK_VIEW,
  },
  {
    title: 'Rooms',
    path: 'rooms',
    icon: RoomsIcon,
    permissionKey: Permission.CAN_HOSTEL_ROOMS_VIEW,
  },
  {
    title: 'Amenities',
    path: 'amenities',
    icon: ReportIcon,
    permissionKey: Permission.CAN_HOSTEL_AMENITIES_VIEW,
  },
  {
    path: 'application-profiles',
    title: 'People',
    icon: PeopleIcon,
    permissionKey: Permission.CAN_MANAGE_HOSTELS_PROFILE_PERMISSIONS_VIEW,
  },
]
