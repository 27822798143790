import {
  ActionButton,
  axios,
  Breadcrumbs,
  DrawerButton,
  PageContent,
  PageHeader,
  Spinner,
} from '@campxdev/shared'
import { useParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AddNoteForm from './AddNoteForm'
import moment from 'moment'
import {
  StyledBox,
  StyledLetter,
  StyledNamecontainer,
  StyledRemarksContainer,
} from './styles'
import TicketPropertysFilter from './TicketPropertysFilter'
import StyledTimeLine from './TimeLine'
import { useQuery } from 'react-query'
import { getTicketDetails } from './services'

function TicketDetails() {
  const params = useParams()
  const { isLoading, data: ticketData } = useQuery('ticketDetail', () =>
    getTicketDetails(params?.ticketId),
  )
  if (isLoading) return <Spinner />
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Tickets', to: '/tickets' },
              { name: `${ticketData?.name}`, to: null },
            ]}
          />
        }
      />
      <PageContent>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <StyledBox sx={{ flexGrow: 1 }}>
            <StyledRemarksContainer>
              <StyledNamecontainer>
                <StyledLetter letter={`${ticketData?.name}`} />
                <Stack gap={'5px'}>
                  <Typography variant="h3">{ticketData?.name}</Typography>
                  <Typography variant="caption">
                    {moment(ticketData?.createdAt).format(
                      'DD MMMM, YYYY  hh:mm: a',
                    )}
                  </Typography>
                </Stack>
              </StyledNamecontainer>
              <Box sx={{ margin: '25px' }}>
                <Typography variant="h3">
                  {ticketData?.service?.name}
                </Typography>
                <Typography variant="subtitle2">
                  {ticketData?.category?.name}
                </Typography>
                <Typography
                  sx={{ marginTop: '15px', maxWidth: '65%' }}
                  variant="body1"
                >
                  {ticketData?.description}
                </Typography>
                <Stack direction={'row'} gap="20px" sx={{ marginTop: '10px' }}>
                  {ticketData?.issueFiles?.map((item, index) => (
                    <img
                      src={item?.url}
                      height={'100px'}
                      width={'200px'}
                      key={index}
                    />
                  ))}
                </Stack>
              </Box>
            </StyledRemarksContainer>
            <Stack direction={'row'} gap={'20px'}>
              <DrawerButton
                anchor={({ open }) => (
                  <ActionButton onClick={open}>Add Note</ActionButton>
                )}
                btnProps={{ variant: 'outlined' }}
                title="Add Note"
                content={({ close }) => <AddNoteForm hideDialog={close} />}
              />
            </Stack>
            <Box marginTop={'40px'}>
              <Typography sx={{ marginBottom: '20px' }} variant="h1">
                Ticket Timeline
              </Typography>

              <StyledTimeLine data={ticketData?.timelineLogs} />
            </Box>
          </StyledBox>
          <TicketPropertysFilter />
        </Box>
      </PageContent>
    </div>
  )
}

export default TicketDetails
