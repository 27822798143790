import { axios } from '@campxdev/shared'

export const reports = {
  async fetchFeeDueData(params) {
    return axios
      .get('/square/hostelers/fee-dues', {
        params: {
          ...params,
        },
      })
      .then((res) => res.data)
  },

  async feePaymentReport(params) {
    return axios
      .get('/paymentx/ledger-reports/hosteler-fee-transactions', {
        params,
      })
      .then((res) => res.data)
  },
}
