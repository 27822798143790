import React from 'react'
import moment from 'moment'
import { Box, styled, Typography } from '@mui/material'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from '@mui/lab'
import { Card } from '@campxdev/shared'

interface TimelineComponentProps {
  data: any
}

function StyledTimeLine({ data }: TimelineComponentProps) {
  return data?.map((item, index) => (
    <StyledTimeLineItem key={index}>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          sx={{ width: '20px', height: '20px' }}
        />
        {!(index === data.length - 1) ? <TimelineConnector /> : ''}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="h6">{item?.message}</Typography>
        <Typography sx={{ marginTop: '10px' }}>
          {' '}
          {moment(item?.createdAt).format(' h:mmA Do MMMM YYYY,  ')}
        </Typography>
        <RenderContent timeLog={item} />
      </TimelineContent>
    </StyledTimeLineItem>
  ))
}
export default StyledTimeLine

const RenderContent = ({ timeLog }) => {
  if (timeLog?.files) {
    return (
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid #1212121A',
          margin: '20px 0px',
          padding: '20px',
          maxWidth: '600px',
        }}
      >
        <Typography sx={{ marginBottom: '3px' }}>{timeLog?.notes}</Typography>
        {timeLog?.files?.map((item) => (
          <img src={item?.url} height={'100px'} width={'200px'} />
        ))}
      </Box>
    )
  }

  if (timeLog?.notes) {
    return (
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid #1212121A',
          margin: '20px 0px',
          padding: '20px',
          maxWidth: '400px',
        }}
      >
        <Typography sx={{ wordWrap: 'break-word' }}>
          {timeLog?.notes}
        </Typography>
      </Box>
    )
  }

  return <></>
}

export const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  '& .MuiTimelineDot-root': {
    backgroundColor: theme.palette.secondary,
  },
  '&:before': {
    content: 'none',
  },
}))
