import moment from 'moment'
import { registrations } from 'services/api-services'
import * as XLSX from 'xlsx'

export async function exportToExcel(filters) {
  const { hostelerBatch, blockId, institutionId } = filters
  const res = await registrations.hostelers.fetchHostelers({
    hostelerBatch,
    blockId: blockId ?? undefined,
    offset: undefined,
    limit: undefined,
    institutionId: institutionId ?? undefined,
  })

  const excelData = res?.result?.map((item) => {
    return {
      'Hosteler ID': item.hostelerDetails?.hostelerId,
      'Admission ID': item.admission?.uniqueId,
      Name: item.admission?.fullName,
      Batch: item.admission?.batch,
      'Roll No': item.admission?.rollNo,
      Mobile: item.admission?.mobile,
      'Father Name': item.admission?.guardianDetails.fatherName,
      'Mother Name': item.admission?.guardianDetails.motherName,
      'Parent Mobile': item.admission?.guardianDetails.fatherMobile,
      'Aadhaar No': item.admission?.personalDetails.aadhaarNo,
      Caste: item.admission?.personalDetails.caste,
      'Block Name': item.hostelerDetails?.room?.block?.name,
      'Room Number': item.hostelerDetails?.room?.roomNumber,
      Amenities: item.hostelerDetails?.room?.amenities[0],
      'Joined Date': moment(item.hostelerDetails.createdAt)?.format(
        'DD-MM-YYYY',
      ),
      'Total Fee': item.fees.totalFee,
      'Total Paid': item.fees.totalPaid,
      'Total Due': item.fees.totalDue,
    }
  })

  const fileName = `Hostelers List.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}
