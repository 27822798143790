import React, { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import {
  axios,
  axiosErrorToast,
  FormSingleSelect,
  FormTextField,
  ImageUpload,
  Media,
  MediaRow,
  Spinner,
} from '@campxdev/shared'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getTicketCategoriesDetails, priorityOptions } from './Services'
import { AxiosRequestConfig } from 'axios'

function TicketsForm({ hideDialog }) {
  const { control, handleSubmit, watch } = useForm({})
  const queryClient = useQueryClient()
  const { data: getTicketCategorie, mutate } = useMutation(
    getTicketCategoriesDetails,
  )

  // const data = id ? mutate(id) : null
  const { data: ticketDetails, isLoading } = useQuery('ticketDetails', () =>
    axios.get(`/square/ticket-categories`).then((res) => res.data.result),
  )
  const id = watch('categoryId')
  const { data: ticketServices, isLoading: loading } = useQuery(
    ['ticketService', id],
    () => getTicketCategoriesDetails(id ? id : undefined),
  )

  const [uploadMedia, setUploadMedia] = useState<Media[]>([])

  const handleUploadImageChange = (v) => {
    setUploadMedia([
      ...uploadMedia,
      { type: 'image', url: v.url, key: v.key, id: v.id },
    ])
  }

  const handleUploadDeleteImage = (key) => {
    setUploadMedia((prev) => prev.filter((m) => m.key !== key))
  }

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/square/tickets',
      data: {
        issueFiles: [uploadMedia[0]?.key],
        ...formData,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('Tickets')
      hideDialog()
      toast.success(' Ticket Created Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  if (isLoading) return <Spinner />
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          label={'Ticket owner'}
          name={'name'}
          control={control}
          required
        />
        <FormTextField
          minRows={2}
          multiline
          label={'Description'}
          name={'description'}
          control={control}
          required
        />
        <FormSingleSelect
          label={'Category'}
          name={'categoryId'}
          control={control}
          options={ticketDetails?.map((item) => {
            return { label: item.name, value: item._id }
          })}
          required
        />
        <FormSingleSelect
          label={'Service'}
          name={'serviceId'}
          control={control}
          options={ticketServices?.services?.map((item) => {
            return { label: item.name, value: item._id }
          })}
          required
        />
        <FormSingleSelect
          label={'Assignee'}
          name={'assignedTo'}
          control={control}
          options={ticketServices?.users?.map((item) => {
            return { label: item.fullName, value: item.id }
          })}
        />
        <FormSingleSelect
          label={'Priority'}
          name={'priority'}
          control={control}
          options={priorityOptions?.map((item) => {
            return { label: item.label, value: item.value }
          })}
        />

        <Stack direction={'row'} gap={0.4}>
          <Typography sx={{ opacity: '0.58' }}>upload Image</Typography>
          <Typography sx={{ color: '#f5222d' }}>*</Typography>
        </Stack>
        {!uploadMedia?.length ? (
          <ImageUpload
            onFileUploaded={handleUploadImageChange}
            postUrl="/square/tickets/upload-issue-file"
          />
        ) : (
          <MediaRow list={uploadMedia} onDelete={handleUploadDeleteImage} />
        )}

        <Stack direction="row" gap={2} mt={2}>
          <Button type="submit" fullWidth>
            Create Ticket
          </Button>
          <Button variant="outlined" fullWidth onClick={() => hideDialog()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default TicketsForm
