import { axios } from '@campxdev/shared'

export const attendance = {
  async getAttendance(params) {
    const res = await axios.get('/square/hostel-attendance', { params })
    return res.data.result
  },

  async getAllStudentsAttendance(params) {
    const res = await axios.get(
      '/square/hostel-attendance/get-all-attendance',
      { params },
    )
    return res.data
  },

  async getAttendanceCount(params) {
    const res = await axios.get(
      '/square/hostel-attendance/get-attendance-count',
      { params },
    )
    return res.data
  },

  async changeAttendanceStatus(body) {
    const res = await axios.post(
      '/square/hostel-attendance/change-attendance-status',
      body,
    )
    return res.data
  },

  async changeBulkAttendanceStatus(body: any) {
    const res = await axios.post(
      '/square/hostel-attendance/change-bulk-attendance-status',
      body,
    )
    return res.data
  },

  async updateAttendance({ id, body }: { id: number; body: any }) {
    const res = await axios.put(`/square/hostel-attendance/${id}`, body)
    return res.data
  },
}
