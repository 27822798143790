import {
  ErrorBoundary,
  LayoutWrapper,
  PageNotFound,
  withRouteWrapper,
} from '@campxdev/shared'
import AppLayout from 'layout/AppLayout/AppLayout'
import { appMenu } from 'layout/appMenu'
import { Outlet } from 'react-router-dom'
import { baseRoutes } from './baseRoutes'

export const mainRoutes = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <LayoutWrapper menu={appMenu}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: withRouteWrapper(baseRoutes),
      },
    ],
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]
