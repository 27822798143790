import { ReactTable } from '@campxdev/shared'
import { Box, Button } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { reports } from 'services/api-services/reports-section/reports'
import * as XLSX from 'xlsx'

export async function exportToExcel(data) {
  if (!data) {
    alert('Data not Available')
    return
  }
  const excelData = data?.map((item, index) => ({
    Name: item.admissionSubValue.fullName,
    Roll_No: item.admissionSubValue.rollNo,
    Paid_Amount: item.debit,
    Date: moment(item.createdAt).format('DD/MM/YYYY'),
    Payment_Method: item.feeTransaction.paymentMethod,
    Receipt_No: item.feeTransaction.receiptNo,
    Admission_Id: item.admissionSubValue.uniqueId,
    Gender: item.admissionSubValue.gender,
    Reference_No: item.feeTransaction.referenceNo,
  }))

  const fileName = `Fee Payments Report.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export default function FeePaymentsReport() {
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
  })

  const { data, isLoading } = useQuery(
    ['FeePaymentReport', ...Object.values(filters)],
    () =>
      reports.feePaymentReport({
        ...filters,
      }),
  )

  const handlePagination = (newSkip: number) => {
    setFilters((prev) => ({
      ...prev,
      skip: newSkip,
    }))
  }

  const handlePageLimit = (value: number) => {
    setFilters((prev) => ({
      ...prev,
      limit: value,
      skip: 0,
    }))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'admissionSubValue.fullName',
      key: 'admissionSubValue.fullName',
    },
    {
      title: 'Roll No.',
      dataIndex: 'admissionSubValue.rollNo',
      key: 'admissionSubValue.rollNo',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'debit',
      key: 'debit',
      render: (cellData) => '₹' + cellData,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (cellData, row) => moment(cellData).format('DD/MM/YYYY'),
    },
    {
      title: 'Payment Method',
      dataIndex: 'feeTransaction.paymentMethod',
      key: 'paymentMethod',
      render: (_, r) => r.feeTransaction.paymentMethod.split('_').join(' '),
    },
    {
      title: 'Reciept No.',
      dataIndex: 'feeTransaction.receiptNo',
      key: 'feeTransaction.receiptNo',
    },

    {
      title: 'Admission No.',
      dataIndex: 'admissionSubValue.uniqueId',
      key: 'admissionSubValue.uniqueId',
    },
    {
      title: 'Gender',
      dataIndex: 'admissionSubValue.gender',
      key: 'admissionSubValue.gender',
    },
    {
      title: 'Reference No.',
      dataIndex: 'feeTransaction.referenceNo',
      key: 'feeTransaction.referenceNo',
    },
  ]

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <Button variant="outlined" onClick={() => exportToExcel(data?.ledgers)}>
          Export
        </Button>
      </Box>

      <ReactTable
        key={'FeePaymentReport'}
        dataSource={data?.ledgers}
        rowKey={'id'}
        columns={columns}
        loading={isLoading}
        pagination={{
          limit: filters.limit,
          onChange: handlePagination,
          totalCount: data?.count,
          onChangeLimit: handlePageLimit,
        }}
      />
    </div>
  )
}
