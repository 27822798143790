import React, { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import {
  axios,
  axiosErrorToast,
  DrawerButton,
  FormTextField,
  ImageUpload,
  Media,
  MediaRow,
} from '@campxdev/shared'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { AxiosRequestConfig } from 'axios'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
const schema = yup.object().shape({
  notes: yup.string().required('Add notes is required'),
})

function AddNoteForm({ hideDialog }) {
  const { ticketId } = useParams()
  const queryClient = useQueryClient()
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })

  const [uploadMedia, setUploadMedia] = useState<Media[]>([])

  const handleUploadImageChange = (v) => {
    setUploadMedia([{ type: 'image', url: v.url, key: v.key, id: v.id }])
  }

  const handleUploadDeleteImage = (key) => {
    setUploadMedia((prev) => prev.filter((m) => m.key !== key))
  }

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/square/tickets/${ticketId}/notes`,
      data: {
        notesFiles: uploadMedia?.map((item) => item.key) ?? [],
        ...formData,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('ticketDetail')
      hideDialog()
      toast.success(' Ticket Created Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          minRows={5}
          multiline
          label={'Add Note'}
          name={'notes'}
          control={control}
          required
        />

        {!uploadMedia?.length ? (
          <ImageUpload
            onFileUploaded={handleUploadImageChange}
            postUrl="/square/tickets/upload-activity-file"
          />
        ) : (
          <MediaRow list={uploadMedia} onDelete={handleUploadDeleteImage} />
        )}

        <Stack direction="row" gap={2} mt={2}>
          <Button type="submit" fullWidth>
            Create Note
          </Button>
          <Button variant="outlined" fullWidth onClick={() => hideDialog()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default AddNoteForm
