import './style.css'
export const DashboardIcon = () => {
  return (
    <svg
      id="apps_2_"
      data-name="apps (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1377"
        data-name="Path 1377"
        d="M4.667,0h-2A2.667,2.667,0,0,0,0,2.667v2A2.667,2.667,0,0,0,2.667,7.333h2A2.667,2.667,0,0,0,7.333,4.667v-2A2.667,2.667,0,0,0,4.667,0ZM6,4.667A1.333,1.333,0,0,1,4.667,6h-2A1.333,1.333,0,0,1,1.333,4.667v-2A1.333,1.333,0,0,1,2.667,1.333h2A1.333,1.333,0,0,1,6,2.667Z"
        fill="#fff"
      />
      <path
        id="Path_1378"
        data-name="Path 1378"
        d="M17.667,0h-2A2.667,2.667,0,0,0,13,2.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,0ZM19,4.667A1.333,1.333,0,0,1,17.667,6h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,2.667Z"
        transform="translate(-4.333)"
        fill="#fff"
      />
      <path
        id="Path_1379"
        data-name="Path 1379"
        d="M4.667,13h-2A2.667,2.667,0,0,0,0,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,4.667,13ZM6,17.667A1.333,1.333,0,0,1,4.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,6,15.667Z"
        transform="translate(0 -4.333)"
        fill="#fff"
      />
      <path
        id="Path_1380"
        data-name="Path 1380"
        d="M17.667,13h-2A2.667,2.667,0,0,0,13,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,13ZM19,17.667A1.333,1.333,0,0,1,17.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,15.667Z"
        transform="translate(-4.333 -4.333)"
        fill="#fff"
      />
    </svg>
  )
}

export const LogsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="clock_5_" data-name="clock (5)" transform="translate(0 0)">
        <path
          id="Path_1791"
          data-name="Path 1791"
          d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.667A6.667,6.667,0,1,1,14.667,8,6.667,6.667,0,0,1,8,14.667Z"
          fill="#fff"
        />
        <path
          id="Path_1792"
          data-name="Path 1792"
          d="M10.386,6a.667.667,0,0,0-.667.667V9.55L7.472,10.958a.668.668,0,0,0,.708,1.133l2.56-1.6a.667.667,0,0,0,.313-.572V6.667A.667.667,0,0,0,10.386,6Z"
          transform="translate(-2.386 -2)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
export const HosteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      viewBox="0 0 18 24"
    >
      <g id="hotel" transform="translate(-3)">
        <path
          id="Path_6714"
          data-name="Path 6714"
          d="M16,0H8A5.006,5.006,0,0,0,3,5V19a5.006,5.006,0,0,0,5,5h2a1,1,0,0,0,1-1V18h2v5a1,1,0,0,0,1,1h2a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,16,0Zm3,19a3,3,0,0,1-3,3H15V18h1a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2H9v4H8a3,3,0,0,1-3-3V5A3,3,0,0,1,8,2h8a3,3,0,0,1,3,3Zm-8-6a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2h1A1,1,0,0,1,11,13Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h1A1,1,0,0,1,16,13ZM11,5a1,1,0,0,1-1,1H9A1,1,0,0,1,9,4h1A1,1,0,0,1,11,5Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h1A1,1,0,0,1,16,5ZM11,9a1,1,0,0,1-1,1H9A1,1,0,0,1,9,8h1A1,1,0,0,1,11,9Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h1A1,1,0,0,1,16,9Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
export const ReportIcon = () => {
  return (
    <span className="white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.333"
        height="16"
        viewBox="0 0 13.333 16"
      >
        <path
          id="document"
          d="M12,9.333a.667.667,0,0,1-.667.667H6A.667.667,0,1,1,6,8.667h5.333A.667.667,0,0,1,12,9.333Zm-2.667,2H6a.667.667,0,1,0,0,1.333H9.333a.667.667,0,1,0,0-1.333Zm6-4.343v5.677A3.337,3.337,0,0,1,12,16H5.333A3.337,3.337,0,0,1,2,12.667V3.333A3.337,3.337,0,0,1,5.333,0h3.01a4.639,4.639,0,0,1,3.3,1.367l2.323,2.324a4.634,4.634,0,0,1,1.367,3.3ZM10.7,2.309a3.34,3.34,0,0,0-.7-.52V4.667a.667.667,0,0,0,.667.667h2.877a3.322,3.322,0,0,0-.521-.7ZM14,6.99c0-.11-.021-.215-.031-.323h-3.3a2,2,0,0,1-2-2v-3.3c-.108-.01-.214-.031-.323-.031H5.333a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2H12a2,2,0,0,0,2-2Z"
          transform="translate(-2 0)"
        />
      </svg>
    </span>
  )
}
export const PaymentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
    >
      <g
        id="credit-card_2_"
        data-name="credit-card (2)"
        transform="translate(0 -3)"
      >
        <circle
          id="Ellipse_212"
          data-name="Ellipse 212"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(4 14)"
          fill="#fff"
        />
        <path
          id="Path_6713"
          data-name="Path 6713"
          d="M19,3H5A5.006,5.006,0,0,0,0,8v8a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V8a5.006,5.006,0,0,0-5-5ZM5,5H19a3,3,0,0,1,3,3H2A3,3,0,0,1,5,5ZM19,19H5a3,3,0,0,1-3-3V10H22v6A3,3,0,0,1,19,19Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
export const RoomsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
    >
      <path
        id="bed"
        d="M19,6H13A4.987,4.987,0,0,0,8.584,8.7,3.464,3.464,0,0,0,6.5,8,3.5,3.5,0,0,0,3,11.5,3.464,3.464,0,0,0,3.351,13H2V3A1,1,0,1,0,0,3V21a1,1,0,0,0,2,0V19H22v2a1,1,0,0,0,2,0V11a5.006,5.006,0,0,0-5-5Zm-9,5a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v2H10Zm-5,.5A1.5,1.5,0,1,1,6.5,13,1.5,1.5,0,0,1,5,11.5ZM2,17V15H22v2Z"
        transform="translate(0 -2)"
        fill="#fff"
      />
    </svg>
  )
}
export const PeopleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.171"
      height="16.337"
      viewBox="0 0 16.171 16.337"
    >
      <g
        id="vuesax_linear_people"
        data-name="vuesax/linear/people"
        transform="translate(-619.429 -312.425)"
      >
        <g id="people" transform="translate(619.979 312.975)">
          <g id="Group" transform="translate(10.061 0)">
            <path
              id="Vector"
              d="M2.016,3.9a.457.457,0,0,0-.143,0,1.951,1.951,0,1,1,.143,0Z"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M.023,3.729A4.254,4.254,0,0,0,3,3.186,1.443,1.443,0,0,0,3,.6,4.28,4.28,0,0,0,0,.068"
              transform="translate(1.215 5.661)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
          </g>
          <g id="Group-2" data-name="Group" transform="translate(0 0)">
            <path
              id="Vector-3"
              data-name="Vector"
              d="M1.88,3.9a.457.457,0,0,1,.143,0,1.951,1.951,0,1,0-.143,0Z"
              transform="translate(1.115 0)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M3.773,3.729A4.254,4.254,0,0,1,.8,3.186,1.443,1.443,0,0,1,.8.6a4.28,4.28,0,0,1,3-.536"
              transform="translate(0 5.661)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
          </g>
          <g id="Group-3" data-name="Group" transform="translate(4.552 5.639)">
            <path
              id="Vector-5"
              data-name="Vector"
              d="M2.016,3.9a.457.457,0,0,0-.143,0,1.951,1.951,0,1,1,.143,0Z"
              transform="translate(0.979 0)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M.8.6a1.443,1.443,0,0,0,0,2.582,4.286,4.286,0,0,0,4.393,0A1.443,1.443,0,0,0,5.192.6,4.321,4.321,0,0,0,.8.6Z"
              transform="translate(0 5.673)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.1"
            />
          </g>
          <path
            id="Vector-7"
            data-name="Vector"
            d="M0,0H15.053V15.053H0Z"
            transform="translate(0.018 0.022)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  )
}

export const ReceiptIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
    >
      <g id="receipt_2_" data-name="receipt (2)" transform="translate(-3)">
        <path
          id="Path_1412"
          data-name="Path 1412"
          d="M11.667,0H6.333A3.337,3.337,0,0,0,3,3.333v12a.667.667,0,0,0,1.043.55l1.4-.959,1.4.959a.667.667,0,0,0,.753,0L9,14.924l1.4.959a.667.667,0,0,0,.754,0l1.4-.959,1.4.958A.667.667,0,0,0,15,15.333v-12A3.337,3.337,0,0,0,11.667,0Zm2,14.067-.733-.5a.667.667,0,0,0-.755,0l-1.4.959-1.4-.959a.667.667,0,0,0-.754,0l-1.4.959-1.4-.959a.667.667,0,0,0-.753,0l-.739.5V3.333a2,2,0,0,1,2-2h5.333a2,2,0,0,1,2,2Z"
          fill="#fff"
        />
        <rect
          id="Rectangle_1393"
          data-name="Rectangle 1393"
          width="6.667"
          height="1.333"
          rx="0.667"
          transform="translate(5.667 5.333)"
          fill="#fff"
        />
        <rect
          id="Rectangle_1394"
          data-name="Rectangle 1394"
          width="5.333"
          height="1.333"
          rx="0.667"
          transform="translate(5.667 8)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const ArchiveIcon = () => {
  return (
    <span className="white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.333"
        height="16"
        viewBox="0 0 13.333 16"
      >
        <g id="archive" transform="translate(-2)">
          <path
            id="Path_17990"
            data-name="Path 17990"
            d="M12,0H5.333A3.337,3.337,0,0,0,2,3.333v9.333A3.337,3.337,0,0,0,5.333,16H12a3.337,3.337,0,0,0,3.333-3.333V3.333A3.337,3.337,0,0,0,12,0ZM5.333,1.333H12a2,2,0,0,1,2,2v4H3.333v-4a2,2,0,0,1,2-2ZM12,14.667H5.333a2,2,0,0,1-2-2v-4H14v4A2,2,0,0,1,12,14.667Z"
          />
          <path
            id="Path_17991"
            data-name="Path 17991"
            d="M10.667,6.333H12A.667.667,0,0,0,12,5H10.667a.667.667,0,1,0,0,1.333Z"
            transform="translate(-2.667 -1.667)"
          />
          <path
            id="Path_17992"
            data-name="Path 17992"
            d="M12,17H10.667a.667.667,0,1,0,0,1.333H12A.667.667,0,0,0,12,17Z"
            transform="translate(-2.667 -5.667)"
          />
        </g>
      </svg>
    </span>
  )
}

export const ReportsIcon = () => {
  return (
    <span className="white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="16"
        viewBox="0 0 12 16"
      >
        <g id="receipt_1_" data-name="receipt (1)" transform="translate(-3)">
          <path
            id="Path_15554"
            data-name="Path 15554"
            d="M11.667,0H6.333A3.337,3.337,0,0,0,3,3.333v12a.667.667,0,0,0,1.043.55l1.4-.959,1.4.959a.667.667,0,0,0,.753,0L9,14.924l1.4.959a.667.667,0,0,0,.754,0l1.4-.959,1.4.958A.667.667,0,0,0,15,15.333v-12A3.337,3.337,0,0,0,11.667,0Zm2,14.067-.733-.5a.667.667,0,0,0-.755,0l-1.4.959-1.4-.959a.667.667,0,0,0-.754,0l-1.4.959-1.4-.959a.667.667,0,0,0-.753,0l-.739.5V3.333a2,2,0,0,1,2-2h5.333a2,2,0,0,1,2,2Z"
            transform="translate(0)"
          />
          <rect
            id="Rectangle_3880"
            data-name="Rectangle 3880"
            width="6.667"
            height="1.333"
            rx="0.667"
            transform="translate(5.667 5.333)"
          />
          <rect
            id="Rectangle_3881"
            data-name="Rectangle 3881"
            width="5.333"
            height="1.333"
            rx="0.667"
            transform="translate(5.667 8)"
          />
        </g>
      </svg>
    </span>
  )
}
export const TicketsIcon = () => {
  return (
    <span className="white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="16"
        viewBox="0 0 12 16"
      >
        <g id="ticket" transform="translate(-3)">
          <path
            id="Path_17967"
            data-name="Path 17967"
            d="M11.667,0H11.58a1.347,1.347,0,0,0-1.294,1.021,1.333,1.333,0,0,1-2.572,0A1.347,1.347,0,0,0,6.42,0H6.333A3.337,3.337,0,0,0,3,3.333V14a2,2,0,0,0,2,2H6.42a1.347,1.347,0,0,0,1.294-1.021,1.333,1.333,0,0,1,2.572,0A1.347,1.347,0,0,0,11.58,16H13a2,2,0,0,0,2-2V3.333A3.337,3.337,0,0,0,11.667,0ZM13,14.667l-1.429-.042a2.667,2.667,0,0,0-5.151.042H5A.667.667,0,0,1,4.333,14V11.333H5.667a.667.667,0,1,0,0-1.333H4.333V3.333a2,2,0,0,1,2-2l.1.042A2.673,2.673,0,0,0,9,3.333a2.714,2.714,0,0,0,2.6-2h.071a2,2,0,0,1,2,2V10H12.333a.667.667,0,0,0,0,1.333h1.333V14A.667.667,0,0,1,13,14.667Z"
          />
          <path
            id="Path_17968"
            data-name="Path 17968"
            d="M12,15H10.667a.667.667,0,0,0,0,1.333H12A.667.667,0,0,0,12,15Z"
            transform="translate(-2.333 -5)"
          />
        </g>
      </svg>
    </span>
  )
}
export const ExamsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
    >
      <path
        id="test_5_"
        data-name="test (5)"
        d="M11.667,11.333a.667.667,0,0,1,0,1.333H11a.667.667,0,0,1,0-1.333ZM10.333,10a.667.667,0,0,1-.667-.667V8.667H8.333v.667A.667.667,0,0,1,7,9.333V6.667a2,2,0,1,1,4,0V9.333A.667.667,0,0,1,10.333,10ZM9.667,7.333V6.667a.667.667,0,1,0-1.333,0v.667ZM8.526,10.867,7.451,11.953a.167.167,0,0,1-.229.007l-.411-.427a.668.668,0,0,0-.956.933l.417.429a1.5,1.5,0,0,0,2.124,0l1.077-1.1a.667.667,0,0,0-.948-.933ZM15,4.438v8.229A3.337,3.337,0,0,1,11.667,16H6.333A3.337,3.337,0,0,1,3,12.667V3.333A3.337,3.337,0,0,1,6.333,0h4.229a3.313,3.313,0,0,1,2.357.977l1.1,1.1A3.313,3.313,0,0,1,15,4.438ZM11.977,1.919a2.028,2.028,0,0,0-.31-.253V3.333h1.667a2.028,2.028,0,0,0-.253-.31Zm1.69,2.747h-2a1.333,1.333,0,0,1-1.333-1.333v-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2h5.333a2,2,0,0,0,2-2Z"
        transform="translate(-3 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const ActivitiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="trophy_1_"
        data-name="trophy (1)"
        d="M10.061,10.667C14.441,10.643,16,8.323,16,6.333a2.333,2.333,0,0,0-1.843-2.279c.091-.258.169-.495.222-.674a2.591,2.591,0,0,0-.417-2.305A2.653,2.653,0,0,0,11.819,0H4.181A2.653,2.653,0,0,0,2.038,1.075,2.591,2.591,0,0,0,1.621,3.38c.053.179.133.416.222.674A2.333,2.333,0,0,0,0,6.333c0,1.989,1.559,4.309,5.939,4.333A3.373,3.373,0,0,1,6,11.281v2.053a1.255,1.255,0,0,1-1.333,1.333H4A.667.667,0,0,0,4,16h8a.667.667,0,0,0,0-1.333h-.661A1.257,1.257,0,0,1,10,13.333V11.28a3.372,3.372,0,0,1,.061-.613Zm3.606-5.333a1,1,0,0,1,1,1c0,1.356-1.073,2.8-4.024,2.98a3.231,3.231,0,0,1,.508-.547,10.088,10.088,0,0,0,2.485-3.439C13.646,5.327,13.655,5.333,13.667,5.333Zm-12.333,1a1,1,0,0,1,1-1c.011,0,.021-.006.031-.007A10.088,10.088,0,0,0,4.849,8.766a3.231,3.231,0,0,1,.508.547C2.406,9.133,1.333,7.689,1.333,6.333Zm5.675,8.333a2.72,2.72,0,0,0,.325-1.333V11.281a4.62,4.62,0,0,0-1.621-3.53A10.225,10.225,0,0,1,2.9,3a1.267,1.267,0,0,1,.207-1.129,1.329,1.329,0,0,1,1.075-.537h7.639a1.329,1.329,0,0,1,1.075.54A1.267,1.267,0,0,1,13.1,3a10.225,10.225,0,0,1-2.813,4.748A4.619,4.619,0,0,0,8.667,11.28v2.053a2.72,2.72,0,0,0,.325,1.333Z"
        transform="translate(0 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const AdmissionsIcon = () => {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1381"
        data-name="Path 1381"
        d="M20.667,10H19.333V8.667a.667.667,0,1,0-1.333,0V10H16.667a.667.667,0,0,0,0,1.333H18v1.333a.667.667,0,1,0,1.333,0V11.333h1.333a.667.667,0,0,0,0-1.333Z"
        transform="translate(-5.333 -2.667)"
        fill="#fff"
      />
      <path
        id="Path_1382"
        data-name="Path 1382"
        d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM7,1.333A2.667,2.667,0,1,1,4.333,4,2.667,2.667,0,0,1,7,1.333Z"
        transform="translate(-1 0)"
        fill="#fff"
      />
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M6,14a6.007,6.007,0,0,0-6,6,.667.667,0,1,0,1.333,0,4.667,4.667,0,1,1,9.333,0A.667.667,0,0,0,12,20,6.007,6.007,0,0,0,6,14Z"
        transform="translate(0 -4.667)"
        fill="#fff"
      />
    </svg>
  )
}

export const NotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.974"
      height="16"
      viewBox="0 0 15.974 16"
    >
      <path
        id="bell-ring"
        d="M13.907,10.22l-2.515,4.1a3.49,3.49,0,0,1-2.58,1.651A3.543,3.543,0,0,1,8.394,16a3.475,3.475,0,0,1-2-.637,3.161,3.161,0,0,1-4.459-4.377l-.877-.875a3.509,3.509,0,0,1,.637-5.467L5.539,2.266a5.906,5.906,0,0,1,6.885.367L13.53,1.528a.667.667,0,1,1,.943.943l-1.1,1.105a5.967,5.967,0,0,1,.539,6.645ZM5.378,14.431,2.9,11.951a1.814,1.814,0,0,0,.309,2.176A1.885,1.885,0,0,0,5.378,14.431Zm6.614-10.4A4.591,4.591,0,0,0,6.247,3.4L2.4,5.777A2.177,2.177,0,0,0,2,9.168l4.86,4.861a2.177,2.177,0,0,0,3.4-.4l2.5-4.083a4.625,4.625,0,0,0-.768-5.517ZM12.845,16a.667.667,0,0,1-.394-1.205,5.755,5.755,0,0,0,2.21-3.6.667.667,0,1,1,1.3.287,7.133,7.133,0,0,1-2.725,4.4.667.667,0,0,1-.393.128ZM.668,3.826A.667.667,0,0,1,.135,2.763,7.128,7.128,0,0,1,4.61.013.667.667,0,0,1,4.877,1.32,5.757,5.757,0,0,0,1.2,3.556a.667.667,0,0,1-.533.27Z"
        transform="translate(-0.004 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const LibraryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.207"
      height="16.294"
      viewBox="0 0 16.207 16.294"
    >
      <path
        id="books"
        d="M15.851,12.979,11.137,1.213A2,2,0,0,0,8.531.117L7.915.369a2.015,2.015,0,0,0-.453.268A1.986,1.986,0,0,0,6-.011H2a2,2,0,0,0-2,2V13.984a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2V5.917l3.534,8.824a2.005,2.005,0,0,0,2.6,1.094l.617-.252a2.008,2.008,0,0,0,1.1-2.6ZM9.045,4.952,10.9,4.2l2.779,6.938-1.851.754ZM8.418,1.6l.616-.252a.667.667,0,0,1,.868.362l.5,1.247-1.851.754-.5-1.243A.667.667,0,0,1,8.419,1.6ZM1.333,4.654h2v6.664h-2Zm3.332,0h2v6.664h-2Zm2-2.666V3.322h-2v-2H6A.667.667,0,0,1,6.664,1.989ZM2,1.323H3.332v2h-2V1.989A.667.667,0,0,1,2,1.323ZM1.333,13.984V12.651h2v2H2A.667.667,0,0,1,1.333,13.984ZM6,14.65H4.665v-2h2v1.333A.667.667,0,0,1,6,14.65Zm8.616-.66a.663.663,0,0,1-.363.359h0l-.617.252a.667.667,0,0,1-.866-.36l-.446-1.114,1.851-.754.443,1.107a.658.658,0,0,1,0,.51Z"
        transform="translate(0.1 0.204)"
        fill="#fff"
        stroke="#121212"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export const PlacementIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="briefcase"
        d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"
        fill="#fff"
      />
    </svg>
  )
}

export const StudentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="users_3_"
        data-name="users (3)"
        d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,0,0,1.333,0V15a3.667,3.667,0,0,1,7.333,0v.333a.667.667,0,0,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"
        fill="#fff"
      />
    </svg>
  )
}

export const HostelsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="building"
        d="M4.667,9.333A.667.667,0,0,1,4,10H3.333a.667.667,0,1,1,0-1.333H4A.667.667,0,0,1,4.667,9.333Zm2.667-.667H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,11.333H3.333a.667.667,0,1,0,0,1.333H4a.667.667,0,1,0,0-1.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,3.333H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,3.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,0,0,0-1.333ZM4,6H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,6ZM7.333,6H6.667a.667.667,0,1,0,0,1.333h.667A.667.667,0,0,0,7.333,6ZM16,6.667v6A3.337,3.337,0,0,1,12.667,16H3.333A3.337,3.337,0,0,1,0,12.667V3.333A3.337,3.337,0,0,1,3.333,0h4a3.337,3.337,0,0,1,3.333,3.333h2A3.337,3.337,0,0,1,16,6.667Zm-12.667,8h6V3.333a2,2,0,0,0-2-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2Zm11.333-8a2,2,0,0,0-2-2h-2v10h2a2,2,0,0,0,2-2Zm-2,2a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,8.667Zm0,2.667a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,11.333Zm0-5.333a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,6Z"
        fill="#fff"
      />
    </svg>
  )
}

export const TaskListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.452"
      height="15.229"
      viewBox="0 0 16.452 15.229"
    >
      <path
        id="list-check"
        d="M2.666,4.007a1.988,1.988,0,0,1-1.415-.586L.222,2.506a.667.667,0,0,1,.888-1l1.057.943a.667.667,0,0,0,.971.027L5.54.192a.667.667,0,0,1,.919.965L4.066,3.433A1.976,1.976,0,0,1,2.666,4.007ZM16,2.674a.667.667,0,0,0-.667-.667H8.666a.667.667,0,0,0,0,1.333h6.666A.667.667,0,0,0,16,2.674ZM4.066,8.766,6.459,6.49a.667.667,0,1,0-.919-.965L3.14,7.812a.682.682,0,0,1-.943,0L1.138,6.755A.667.667,0,1,0,.195,7.7L1.252,8.755a2,2,0,0,0,2.817.011ZM16,8.007a.667.667,0,0,0-.667-.667H8.666a.667.667,0,0,0,0,1.333h6.666A.667.667,0,0,0,16,8.007ZM4.066,14.1l2.39-2.276a.667.667,0,1,0-.919-.965l-2.4,2.287a.667.667,0,0,1-.971-.027L1.11,12.176a.667.667,0,1,0-.888,1l1.029.916a2,2,0,0,0,2.817.011ZM16,13.341a.667.667,0,0,0-.667-.667H8.666a.667.667,0,1,0,0,1.333h6.666A.667.667,0,0,0,16,13.341Z"
        transform="translate(0.253 0.225)"
        fill="#fff"
        stroke="#fff"
        stroke-width="0.4"
      />
    </svg>
  )
}

export const CalendarIcon = () => {
  return (
    <span className="white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g id="calendar" transform="translate(0 0)">
          <path
            id="Path_17993"
            data-name="Path 17993"
            d="M12.667,1.333H12V.667a.667.667,0,0,0-1.333,0v.667H5.333V.667A.667.667,0,1,0,4,.667v.667H3.333A3.337,3.337,0,0,0,0,4.667v8A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667v-8A3.337,3.337,0,0,0,12.667,1.333ZM1.333,4.667a2,2,0,0,1,2-2h9.333a2,2,0,0,1,2,2v.667H1.333Zm11.333,10H3.333a2,2,0,0,1-2-2v-6H14.667v6A2,2,0,0,1,12.667,14.667Z"
            transform="translate(0 0)"
          />
          <circle
            id="Ellipse_535"
            data-name="Ellipse 535"
            cx="1"
            cy="1"
            r="1"
            transform="translate(7 9)"
          />
          <circle
            id="Ellipse_536"
            data-name="Ellipse 536"
            cx="1"
            cy="1"
            r="1"
            transform="translate(3.667 9)"
          />
          <circle
            id="Ellipse_537"
            data-name="Ellipse 537"
            cx="1"
            cy="1"
            r="1"
            transform="translate(10.333 9)"
          />
        </g>
      </svg>
    </span>
  )
}
