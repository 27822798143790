import { Permission } from '@campxdev/shared'
import {
  Amenities,
  Attendance,
  Blocks,
  FeePayments,
  HostelBlockView,
  Hostelers,
  HostelersView,
  People,
  Rooms,
  TicketingSystem,
} from 'pages'
import TicketsSubpage from 'pages/Configuration/TicketingSystem/View/TicketsSubpage'

import ReportsViewLayout from 'pages/Reports/ReportsViewLayout'
import Tickets from 'pages/Tickets'
import TicketDetails from 'pages/Tickets/TicketDetails'
import { Navigate } from 'react-router-dom'

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="/hostelers" />,
    permissionKey: Permission.CAN_HOSTELER_VIEW,
  },
  {
    path: '/tickets',
    element: <Tickets />,
    permissionKey: Permission.CAN_HOSTEL_TICKETS_VIEW,
  },
  {
    path: '/tickets/:ticketId',
    element: <TicketDetails />,
    permissionKey: Permission.CAN_HOSTEL_TICKETS_VIEW,
  },
  {
    path: '/configuration',
    element: <TicketingSystem />,
    permissionKey: Permission.CAN_HOSTEL_TICKET_GROUP_VIEW,
  },
  {
    path: '/configuration/:id',
    element: <TicketsSubpage />,
    permissionKey: Permission.CAN_HOSTEL_TICKET_GROUP_VIEW,
  },
  {
    path: '/hostelers',
    element: <Hostelers />,
    permissionKey: Permission.CAN_HOSTELER_VIEW,
  },
  {
    element: <HostelersView />,
    path: '/hostelers/:id/:hostelerBatch',
    permissionKey: Permission.CAN_HOSTELER_VIEW,
  },
  {
    element: <FeePayments />,
    path: '/fee-payments',
    permissionKey: Permission.CAN_HOSTEL_FEE_PAYMENTS_VIEW,
  },
  {
    element: <Attendance />,
    path: '/attendance-marking',
    permissionKey: Permission.CAN_HOSTELER_ATTENDANCE_VIEW,
  },
  {
    path: '/reports/*',
    element: <ReportsViewLayout />,
    permissionKey: Permission.CAN_HOSTEL_REPORTS_VIEW,
  },
  {
    path: '/rooms',
    element: <Rooms />,
    permissionKey: Permission.CAN_HOSTEL_ROOMS_VIEW,
  },
  {
    path: '/blocks',
    element: <Blocks />,
    permissionKey: Permission.CAN_HOSTEL_BLOCK_VIEW,
  },
  {
    path: '/blocks/:blockId',
    element: <HostelBlockView />,
    permissionKey: Permission.CAN_HOSTEL_BLOCK_VIEW,
  },
  {
    path: '/amenities',
    element: <Amenities />,
    permissionKey: Permission.CAN_HOSTEL_AMENITIES_VIEW,
  },
  {
    path: 'application-profiles',
    element: <People />,
    permissionKey: Permission.CAN_MANAGE_HOSTELS_PROFILE_PERMISSIONS_VIEW,
  },
]
