import {
  ActionButton,
  DrawerButton,
  PageContent,
  PageHeader,
  Spinner,
  Table,
  ViewButton,
} from '@campxdev/shared'
import { Box } from '@mui/material'
import { Store } from 'pullstate'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fetchTicketDetails } from './Services'
import TicketsFilter from './TicketsFilter'
import TicketsForm from './TicketsForm'
import TicketsSearch from './TicketsSearch'

interface IStore {
  searchTickeId: any
  assignedTo: number
  createdBy: any
  priority: string
  statuses: string
  limit: number
  offset: number
  categoryId: any
  search: any
}
export const ticketStore = new Store<IStore>({
  searchTickeId: null,
  assignedTo: null,
  createdBy: '',
  priority: '',
  statuses: '',
  limit: 20,
  offset: 0,
  categoryId: '',
  search: '',
})

function Tickets() {
  const ticketState = ticketStore.useState((s) => s)
  const navigate = useNavigate()
  const { data: ticketsData, isLoading: ticketLoading } = useQuery(
    [
      'Tickets',
      ticketState.search,
      ticketState.priority,
      ticketState.statuses,
      ticketState.categoryId,
      ticketState.assignedTo,
    ],
    () =>
      fetchTicketDetails({
        search: ticketState?.search,
        priority: ticketState?.priority,
        statuses: ticketState?.statuses,
        categoryId: ticketState?.categoryId,
        assignedTo: ticketState?.assignedTo,
      }),
  )
  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category.name',
      key: 'category',
    },
    {
      title: 'Service',
      dataIndex: 'service.name',
      key: 'service',
    },

    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (_, row) => {
        return row?.priority ? row.priority : 'undefined'
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return <ViewButton onClick={() => navigate(`${row._id}`)} />
      },
    },
  ]

  return (
    <>
      <PageHeader
        title="Tickets"
        actions={[
          <DrawerButton
            title="Add New Ticket"
            key={'1'}
            anchor={({ open }) => (
              <ActionButton onClick={open}>New Ticket</ActionButton>
            )}
            content={({ close }) => <TicketsForm hideDialog={close} />}
          />,
        ]}
      />
      <PageContent>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <TicketsSearch />
            <Table
              key={'Tickets'}
              dataSource={ticketsData?.ticketData}
              rowKey={'id'}
              columns={columns}
              loading={ticketLoading}
              pagination={null}
            />
          </Box>

          <TicketsFilter />
        </Box>
      </PageContent>
    </>
  )
}

export default Tickets
