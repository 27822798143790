import { axios } from '@campxdev/shared'
export const priorityOptions = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
]
export const ticketStatusOptions = [
  {
    label: 'OPEN',
    value: 'OPEN',
  },
  {
    label: 'IN_PROGRESS',
    value: 'IN_PROGRESS',
  },
  {
    label: 'RESOLVED',
    value: 'RESOLVED',
  },
  {
    label: 'CLOSED',
    value: 'CLOSED',
  },
]
export const getCategories = async () => {
  let subjectData = await axios.get(`/square/ticket-categories`)
  return subjectData?.data
}
export const getTicketCategories = async (id) => {
  let subjectData = await axios.get(`/square/ticket-categories/${id}`)
  return subjectData?.data
}
export const getTicketCategoriesDetails = async (id) => {
  if (id === undefined) return
  let subjectData = await axios.get(`/square/ticket-categories/${id}`)
  return subjectData?.data
}
// export const getTicketDetails = async (id) => {
//   let subjectData = await axios.get(`/square/ticket/${id}`)
//   return subjectData?.data
// }
export const fetchTicketDetails = async (params: {
  search?: string
  priority?: string
  statuses?: string
  categoryId?: any
  assignedTo?: number
}) => {
  let ticketData = await axios.get('/square/tickets', {
    params,
  })
  return {
    ticketData: ticketData.data.result,
  }
}
