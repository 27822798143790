import { axios, axiosErrorToast, FormSingleSelect } from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { batchOptions, getTicketDetails, ticketStatusOptions } from './services'
import { useParams } from 'react-router-dom'
import { getTicketCategories } from '../Services'
const schema = yup.object().shape({
  assignedTo: yup.string().required('Assignee is required'),
  priority: yup.string().required('priority is required'),
  status: yup.string().required('status is required'),
})

function TicketPropertysFilter() {
  const { ticketId } = useParams()
  const queryClient = useQueryClient()

  const { isLoading, data: ticketDetail } = useQuery('ticketDetail', () =>
    getTicketDetails(ticketId),
  )

  const { data: ticketCategorieyDetail } = useQuery(
    ['ticketCategorieyDetail', ticketDetail?.category?._id],
    () => getTicketCategories(ticketDetail?.category?._id),
    {
      enabled: Boolean(ticketDetail?.category?._id),
    },
  )

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/square/tickets/${ticketId}`,
      data: {
        serviceId: ticketDetail?.service?._id,
        categoryId: ticketDetail?.category?._id,
        name: ticketDetail?.name,
        description: ticketDetail?.description,
        ...formData,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('ticketDetail')
      toast.success(' Ticket Assigned updated Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  })
  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '350px' }}>
          <StyledFiltersContainer>
            <StyledFiltersHeader>
              <Typography variant="h6">Ticket Properties</Typography>
            </StyledFiltersHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2.5} sx={{ padding: '20px' }}>
                <FormSingleSelect
                  name="assignedTo"
                  label={'Assigned To'}
                  control={control}
                  required
                  options={ticketCategorieyDetail?.users?.map((item) => ({
                    label: item.fullName,
                    value: item.id,
                  }))}
                />
                <FormSingleSelect
                  name="priority"
                  label={'Priority'}
                  control={control}
                  required
                  options={batchOptions?.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
                <FormSingleSelect
                  name="status"
                  label={'Status'}
                  control={control}
                  required
                  options={ticketStatusOptions?.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />

                <Button type="submit" fullWidth>
                  Update
                </Button>
              </Stack>
            </form>
          </StyledFiltersContainer>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketPropertysFilter

export const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  border: theme?.borders?.grayLight,
  borderRadius: '10px',
  maxWidth: '350px',
  flexShrink: 0,
}))
export const StyledFiltersHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.light,
  padding: '20px',
  borderRadius: '10px 10px 0 0',
}))
