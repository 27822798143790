import { TextField } from '@campxdev/shared'
import { Search } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import { ticketStore } from './Tickets'

function TicketsSearch() {
  return (
    <TextField
      sx={{ maxWidth: '600px', marginBottom: '20px' }}
      placeholder={'Search by  Name'}
      value={null}
      onChange={(e) => {
        ticketStore.update((s) => {
          s.search = e.target.value
        })
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default TicketsSearch
