import {
  FormTextField,
  FormSingleSelect,
  axios,
  axiosErrorToast,
  FormAutoCompleteSearch,
} from '@campxdev/shared'
import { Button, Stack } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useForm } from 'react-hook-form'
import { useQueryClient, useQuery } from 'react-query'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { toast } from 'react-toastify'

const schema = yup.object().shape({
  // userId: yup.any().required('userId is required'),
})

function UserForm({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
}) {
  const queryClient = useQueryClient()
  const { id } = useParams()
  const USER_TYPES = {
    'Staff User': 'staff_user',
    'Faculty User': 'non_staff_user',
    'student User': 'student',
  }
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
    },
  })
  const { data: usersData, isLoading } = useQuery('Users', () =>
    axios
      .get(`/square/users?type=${USER_TYPES['Staff User']}`)
      .then((res) => res.data),
  )

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/square/ticket-categories/users/add',
      data: {
        categoryId: id,
        ...formData,
        userId: formData.userId.value,
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('TicketDetails')
      hideDialog()
      toast.success('Created Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormAutoCompleteSearch
          label={'User'}
          name={'userId'}
          control={control}
          options={usersData?.result?.map((item) => ({
            label: item?.fullName,
            value: item?.id,
          }))}
        />
        <Stack direction="row" gap={2} mt={2}>
          <Button type="submit" fullWidth>
            {data ? 'Edit User ' : 'Add User '}
          </Button>
          <Button variant="outlined" fullWidth onClick={() => hideDialog()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
export default UserForm
